import { Avatar, Button, Paper, Grid, Typography, Container, ListItem,ListItemIcon,ListItemText, Divider,ButtonBase } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useDispatch } from 'react-redux';
import {useHistory} from 'react-router-dom'
import React,{useState} from 'react'
import { signin, signup } from '../../Redux/actions/auth';///
import Input from './input';
import useStyles from './style';
import *as GiIcons from 'react-icons/gi';
import *as FcIcons from 'react-icons/fc';
import GooglFceIcon from './vticon.png'

const Auth = () => {

    const initialState = { firstName: '', lastName: '', email: '', password: '', confirmPassword: '' };
    const history= useHistory();
    const dispatch = useDispatch();
    const [isSignup, setIsSignup]= useState(false);
    const classes = useStyles();

    const [showPassword, setShowPassword] = useState(false);
    const [form, setForm] = useState(initialState);
    const handleShowPassword = () => setShowPassword(!showPassword);
    const handleChange = (e) => setForm({ ...form, [e.target.name]: e.target.value });

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isSignup) {
          dispatch(signup(form, history)); /// History is passed to navigate once something has happened 
        } else {
          dispatch(signin(form, history));
        }
        
      };

    const switchMode = ()=> {
          setIsSignup(!isSignup)
          setShowPassword(false)
        }
    

    return (
        <Container component="main" maxWidth="xs">
      <Paper  className={classes.paper} elevation={6}>
      
      <ListItem  button key="1">
      {/* <ListItemIcon> */}
      <img src={GooglFceIcon} alt="title" style={{height:"40px", width:"40px"}}></img>
      <h3 style={{margin:"0 0 0 10px"}}><strong>VT Exquisite Home Care</strong></h3>
       {/* VT EXquisite Home Care</ListItemIcon> */}
        {/* <ListItemText style={{color:"black"}} align="center" primary="VT EXquisite Home Care" /> */}
      </ListItem>
        {/* <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar> */}
        <Typography component="h1" variant="h5">{ isSignup ? 'Sign up' : 'Sign in' }</Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            { isSignup && (
            <>
              <Input name="firstName" label="First Name" handleChange={handleChange} autoFocus half />
              <Input name="lastName" label="Last Name" handleChange={handleChange} half />
            </>
            )}
            <Input name="email" label="Email Address" handleChange={handleChange} type="email" />
            <Input name="password" label="Password" handleChange={handleChange} type={showPassword ? 'text' : 'password'} handleShowPassword={handleShowPassword} />
            { isSignup && <Input name="confirmPassword" label="Repeat Password" handleChange={handleChange} type="password" /> }
          </Grid>
          <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
            { isSignup ? 'Sign Up' : 'Sign In' }
          </Button>
          
          <ListItem key="1">
          <Divider style={{ margin: '0 0', minWidth:"150px", backgroundColor:"#999999" }} />
          <ListItemText style={{color:"black"}} align="center" primary="or" />
            
          <Divider style={{ margin: '0 0', minWidth:"150px",backgroundColor:"#999999" }} />
          </ListItem>

          <Paper elevation={7} style={{ backgroundColor:"#4285f4", borderRadius:"20px"}}>
          <ListItem  button key="1">
            <ListItemIcon>
            <Avatar className={classes.avatar1}>
            <FcIcons.FcGoogle style={{fontSize:"1.4rem", color:"#e61c76"}}/>
            </Avatar>
            </ListItemIcon>
            <ListItemText style={{color:"white"}} align="center" primary="Sign in with Google" />
          </ListItem>
          </Paper>
          
         
         
            <ListItem style={{marginTop:"40px"}}   key="1">
            <ButtonBase  className={classes.bottomText} component="span" name="test"  onClick={switchMode} >
            <ListItemText  style={{color:"black" , fontSize:"0.8rem"}}  primary={ isSignup ? 'Already have an account? Sign in' : "Don't have an account? Sign Up" } />
            </ButtonBase>
              <Divider orientation="vertical" style={{height:"20px", backgroundColor:"#999999", margin:"0 10px" }} />
              <ButtonBase component="span" name="test" className={classes.cardAction} onClick={switchMode} >
            <ListItemText className={classes.bottomText} style={{ color:"black" , fontsize:".8rem"}}  primary="Forgot password?" />
            </ButtonBase>
              </ListItem>
           
          </form>
          </Paper>
         </Container>
    )
}

export default Auth
