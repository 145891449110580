/** @jsx jsx */
import React from 'react';
import { FaStar } from 'react-icons/fa';
import { jsx, Container, Heading, Text, Box, Image } from 'theme-ui';

const Rating = ({ rating }) => {
  const totalRating = [];
  for (let i = 0; i < 5; i++) {
    if (i < rating) {
      totalRating.push(
        <li className="star" key={`rating-key${i}`}>
          <FaStar />
        </li>
      );
    } else {
      totalRating.push(
        <li className="star-o" key={`rating-key${i}`}>
          <FaStar />
        </li>
      );
    }
  }

  return (
    <Box sx={styles.reviewCard}>


    <div className="rating">
      <ul>{totalRating}</ul>
    </div>
    </Box>
  );
};

const styles = {

  reviewCard: {
  
    '.rating': {
      mb: [1, null, null, 2],
     
      ul: {
        pl: 0,
        listStyle: 'none',
        mb: 0,
        display: 'flex',
      },
      svg: {
        marginRight: '2px',
        '&:last-of-type': {
          marginRight: 0,
        },
      },
      '.star': {
        color: '#fcf649',
        mr: '3px',
        fontSize:"20px"
      },
      '.star-o': {
        color: '#ddd',
        mr: '3px',
      },
    },
    '.card-footer': {
      display: 'flex',
      alignItems: 'center',
      marginTop: [5, null, null, '33px'],
      '.image': {
        flexShrink: 0,
        mr: [3, null, null, 4],
        display: 'flex',
        img: {
          width: '55px',
          height: '55px',
          borderRadius: '50%',
          objectFit: 'cover',
        },
      },
    },
  },

};

export default Rating;
