import React from 'react'
import useStyles from "./styles";
import { Card, CardActions, CardContent, CardMedia, Button, Typography,ButtonBase, Avatar,List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core/';
import { useHistory } from 'react-router-dom';
import Rating from '../ratings'
import *as FaIcons from 'react-icons/fa';
import *as ImIcons from 'react-icons/im';
import *as RiIcons from 'react-icons/ri';
import *as GiIcons from 'react-icons/gi';
// import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';

import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
// import DeleteIcon from '@material-ui/icons/Delete';
// import moment from 'moment';
// import ThumbUpAltOutlined from '@material-ui/icons/ThumbUpAltOutlined';


export default function Avatar1({worker}) {
  const history = useHistory();


  const openPost = (e) => {
    // dispatch(getPost(post._id, history));

    history.push(`/login-page/${worker._id}`);
  };

    const classes = useStyles();
    return (
        <Card raised elevation={6} className={classes.card}>
         <ButtonBase
        component="span"
        name="test"
        className={classes.cardAction}
        onClick={openPost}
      >
      <Avatar alt="Remy Sharp" src={require("./cna.jpeg")} style={{  height:"100px", width:"100px", margin:"20px", alignText:"center"}} />
      <Typography className={classes.title1} gutterBottom variant="h6" component="h2">{worker.name}</Typography>
      <ListItem className={classes.title2} button key="1">
            <ListItemIcon><ImIcons.ImLocation2 style={{fontSize:"1rem", opacity:".7",margin:"4px 4px 0 0 "}}/>  Queens, NY</ListItemIcon>
            {/* <ListItemText primary="Queens, NY" /> */}
          </ListItem>
      
      {/* <CardMedia className={classes.media} image={require("./cna.jpeg")} title="This Title" /> */}
      {/* <div className={classes.overlay}>
        <Typography variant="h6"></Typography>
        <Typography variant="body2"> 1 min ago</Typography>
      </div> */}
      
      {/* <div className={classes.overlay2}>
        <Button style={{ color: 'black' }} size="small" ><MoreHorizIcon fontSize="default" /></Button>
      </div> */}
      {/* <div className={classes.details}>
        <Typography variant="body2" color="textSecondary" component="h2"></Typography> 
        
      </div> */}
      {/* <Typography className={classes.title} gutterBottom variant="h5" component="h2">{worker.name}</Typography> */}
      <CardContent>
      <div style={{padding: '0 20px 0 20px'}}>
      <hr className={classes.hr1} style={{padding:"0 60px 0 60px", maxWidth:"350px", border:"1px solid #14e044 ", textAlign:"center"}}/>
      </div>
        {/* <Rating style={{ position: 'relative', left:"110px", bottom:"10px",}}  rating='5' />
        <Typography className={classes.body1} variant="body2" color="textSecondary" component="p">${worker.rate}-$30</Typography>
        <Typography className={classes.body1} variant="body2" color="textSecondary" component="p">5 years exp.</Typography>
        <Typography className={classes.body1} variant="body2" color="textSecondary" component="p">6 hours ago</Typography>
        <Typography className={classes.body1} variant="body2" color="textSecondary" component="p"></Typography> */}
        <ListItem button key="1">
            <ListItemIcon style={{fontSize:"1rem"}}> <Rating  rating='5'/></ListItemIcon>
            <ListItemText style={{margin:"0 0 14px 15px", opacity:".7",fontSize:"1rem"}} primary="(5)" />
          </ListItem>
          <ListItem button key="1">
            <ListItemIcon><FaIcons.FaMoneyBillWave style={{fontSize:"1rem", color:"#e61c76"}}/></ListItemIcon>
            <ListItemText style={{fontSize:".7rem"}} primary={`$${worker.rate}- Hourly Rate`} />
          </ListItem>
          
          <ListItem button key="1">
            <ListItemIcon><RiIcons.RiTimeFill style={{fontSize:"1rem", color:"#e61c76"}}/></ListItemIcon>
            <ListItemText style={{fontSize:".7rem"}}primary="5 years Experience" />
          </ListItem>
          <ListItem button key="1">
            <ListItemIcon><GiIcons.GiTimeTrap style={{fontSize:"1rem", color:"#e61c76"}}/></ListItemIcon>
            <ListItemText style={{fontSize:".7rem"}}primary="8 Hours ago" />
          </ListItem>
      </CardContent>
      </ButtonBase>
      <CardActions className={classes.cardActions}>
        <Button size="small" color="primary"></Button>
        {/* <Button size="small" color="primary" disabled={!user?.result} onClick={() => dispatch(deletePost(post._id))}><DeleteIcon fontSize="small" /> Delete</Button> */}
        
      </CardActions>
    </Card>
    )
}

