import React, { useState, useEffect } from 'react';
import useStyles from "./style";
import { useDispatch, useSelector } from 'react-redux';
import {useHistory} from 'react-router-dom'
import { TextField, Button, Typography, Paper } from '@material-ui/core';
import FileBase from 'react-file-base64';
import { workersSignup} from '../../../Redux/actions/auth';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Input from '../../../mystuff/Auth/input';

export default function WorkersSignUp({currentId, setCurrentId}) {

    const post = useSelector((state) => currentId? state.posts.find((post)=> post._id== currentId): null)  /// we know that this is called post because we gave it the name "post" in the reducer section 
    const dispatch = useDispatch();
    const [WorkersData, setWorkersData] = useState({ name: '', rate: '', city: '', email: '', selectedFile: '' , password:''});

    const [showPassword, setShowPassword] = useState(false);
    const handleShowPassword = () => setShowPassword(!showPassword);
    const history= useHistory();


    useEffect(()=>{ if(post) setWorkersData(post)}, [post])

    const clear = () => {
      
      setWorkersData({name: '', rate: '', city: '', email: '', selectedFile: '' , password:''})
      };
    
      const handleSubmit =  (e) => {
        e.preventDefault();

         dispatch(workersSignup(WorkersData, history));
        
        clear()
          
       
      };

    const classes = useStyles();
    return (
        <Paper className={classes.paper}>
      <form autoComplete="off" noValidate className={`${classes.root} ${classes.form}`} onSubmit={handleSubmit}>
        <Typography variant="h6">  { currentId ? 'Editing': 'Sign up below'}  </Typography>
        <TextField name="name" variant="outlined" label="Name" fullWidth value={WorkersData.name} onChange={(e) => setWorkersData({ ...WorkersData, name: e.target.value })} />
        <TextField name="rate" variant="outlined" label="Rate" fullWidth value={WorkersData.rate} onChange={(e) => setWorkersData({ ...WorkersData, rate: e.target.value })} />
        <TextField name="city" variant="outlined" label="City" fullWidth multiline rows={4} value={WorkersData.city} onChange={(e) => setWorkersData({ ...WorkersData, city: e.target.value })} />
        <Input name="email" label="Email Address" value={WorkersData.email} handleChange={(e) => setWorkersData({ ...WorkersData, email: e.target.value })} type="email" />
        <Input name="password" label="Password" value={WorkersData.password} handleChange={(e) => setWorkersData({ ...WorkersData, password: e.target.value })} type={showPassword ? 'text' : 'password'} handleShowPassword={handleShowPassword} />
        <div className={classes.fileInput}><FileBase type="file" multiple={false} onDone={({ base64 }) => setWorkersData({ ...WorkersData, selectedFile: base64 })} /></div>
        <Button className={classes.buttonSubmit} variant="contained" color="primary" size="large" type="submit" fullWidth>Submit</Button>
        <Button variant="contained" color="secondary" size="small" onClick={clear} fullWidth>Clear</Button>
      </form>
    </Paper>
    )
}
