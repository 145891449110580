import { AppBar, Badge, Grid, IconButton, InputBase, makeStyles, Toolbar, Avatar,Typography } from '@material-ui/core'
import { deepPurple } from '@material-ui/core/colors';
import React, { useState, useEffect } from 'react';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SearchIcon from '@material-ui/icons/Search';
import MenuIcon from '@material-ui/icons/Menu';
import { Link, useHistory, useLocation } from 'react-router-dom';
import decode from 'jwt-decode';


const drawerWidth = 240;

const useStyles = makeStyles( theme =>({

    root: {

        backgroundColor: "#fff",
        display: 'flex',
      flexDirection: 'row',
        
    },
    color:{
      '& .MuiAppBar-colorPrimary ': {

        backgroundColor: "#ffffff",
    }
    },
    appBar: {
      borderRadius: 15,
      margin: '30px 0',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px 50px',
    
      
    },
    purple: {
      color: theme.palette.getContrastText(deepPurple[500]),
      backgroundColor: deepPurple[500],
      margin:"10px"
    },
    profile: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '400px',
    },
    brandContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    userName: {
      display: 'flex',
      alignItems: 'center',
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'flex-end',
      // width: '400px',
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },

    searchInput:{
      display: 'flex',
      justifyContent: 'flex-end',
        opacity: "0.6",
        padding:"0px 8px",
        fontSize:"0.8rem",
        '&:hover':{
            backgroundColor: "#f2f2f2"
        },
        '& .MuiSvgIcon-root': {

            marginRight: theme.spacing(1)
        }


    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
          width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: drawerWidth,
        },
      },
}))


//// To customize things in material ui : go to the website and read up on it  in particular "mui" this is the class names given to must of the componets 
//// All so when dealing with these components, some of them have root and label. Root is the parent/ outter component and label is the child/ sub component
/// classes= {{root: classes.btnroot}, label: classes.btnroot}- two different classes being used here. One for the class that was creatd and the othe is an actual function used to change the label and root

export default function Header() {

    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    const location = useLocation();
    const history = useHistory();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  useEffect(() => {
    const token = user?.token;

    if (token) {
      const decodedToken = decode(token);

      if (decodedToken.exp * 1000 < new Date().getTime()) ;
    }

    setUser(JSON.parse(localStorage.getItem('profile')));
    console.log(user)
  }, [location]);


    const classes = useStyles();
    return (
      <div className={classes.color}> 
      <AppBar position= "fixed" className={classes.appBar}>
      <div className={classes.brandContainer}>

              <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          </div>
      <Toolbar className={classes.toolbar} >
      
      
       <div className={classes.profile}>
       {/* <InputBase className={classes.searchInput} placeholder="Search" startAdornment={<SearchIcon fontSize="small"/>}/> */}

          <Avatar className={classes.purple} alt={user?.result.name} src={user?.result.imageUrl}>{user?.result.name.charAt(0)}</Avatar>
          <Typography  color="primary" className={classes.userName} variant="h6">{user?.result.name}</Typography>
            <IconButton>

                    <Badge badgeContent={2} color="secondary">
                        <NotificationsIcon fontSize="small"/>
                    </Badge>
            </IconButton>
            <IconButton>

                    <Badge badgeContent={2} color="secondary">
                        <ChatBubbleOutlineIcon fontSize="small"/>
                    </Badge>
            </IconButton>

            <IconButton>
            <ExitToAppIcon/>

            </IconButton>

       </div>
    


     
     </Toolbar>


     </AppBar>
     </div>
    )
}

// <Grid container alignItems="center">

// <Grid item  >
// <IconButton
//    color="inherit"
//    aria-label="open drawer"
//    edge="start"
//    onClick={handleDrawerToggle}
//    className={classes.menuButton}
//  >
//    <MenuIcon />
//  </IconButton>
//  <InputBase className={classes.searchInput} placeholder="Search" startAdornment={<SearchIcon fontSize="small"/>}/>

// </Grid>
// <Grid item sm>

// </Grid>
// <Grid item >
// <Avatar className={classes.purple} alt={user?.result.name} src={user?.result.imageUrl}>{user?.result.name.charAt(0)}</Avatar>

//    <IconButton>

//            <Badge badgeContent={2} color="secondary">
//                <NotificationsIcon fontSize="small"/>
//            </Badge>
//     </IconButton>
//    <IconButton>

//            <Badge badgeContent={2} color="secondary">
//                <ChatBubbleOutlineIcon fontSize="small"/>
//            </Badge>
//    </IconButton>

//    <IconButton>
//    <ExitToAppIcon/>

//    </IconButton>


// </Grid>
// </Grid>
