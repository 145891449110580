import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PhoneIcon from '@material-ui/icons/Phone';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import HelpIcon from '@material-ui/icons/Help';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import ThumbDown from '@material-ui/icons/ThumbDown';
import ThumbUp from '@material-ui/icons/ThumbUp';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Avatar from './Avatar/avatar'
import {WorkersData} from '../../components/Data/workers'
import { useParams, useHistory } from 'react-router-dom';
import { getWorker,getWorkers } from '../../../Redux/actions/auth';
import { Grid } from '@material-ui/core'
import { useDispatch,useSelector } from 'react-redux';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={10}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-prevent-tab-${index}`,
    'aria-controls': `scrollable-prevent-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    "& .MuiTab-root ":{
      minWidth:"370px",
      
     
     
    },
    
  },
  
}));

export default function ScrollableTabsButtonPrevent() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const {workers} = useSelector((state) => state.workersReducer);
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

 useEffect(() => {
    dispatch(getWorkers());
  }, [dispatch]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log(workers)
  // if (!workers.length) return "No Posts" 
  return (
    <div className={classes.root}>
      <AppBar  style={{borderRadius: 15}} position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="off"
          aria-label="scrollable prevent tabs example"
          centered
        >
          <Tab   label="Assigned" aria-label="phone" {...a11yProps(0)} />
          <Tab  label="Available"  aria-label="favorite" {...a11yProps(1)} />
          <Tab label="Requested"   aria-label="person" {...a11yProps(2)} />
          {/* <Tab icon={<HelpIcon />} aria-label="help" {...a11yProps(3)} />
          <Tab icon={<ShoppingBasket />} aria-label="shopping" {...a11yProps(4)} />
          <Tab icon={<ThumbDown />} aria-label="up" {...a11yProps(5)} />
          <Tab icon={<ThumbUp />} aria-label="down" {...a11yProps(6)} /> */}
        </Tabs>
      </AppBar>
      <TabPanel  style={{borderRadius: 15}} value={value} index={0}>
      <Grid  container alignItems="stretch" spacing={8}>
                {workers.map((worker) => (
            <Grid key={worker._id} item xs={12} sm={4} md={4}>
              <Avatar worker={worker}   />
            </Grid>
          ))}
                </Grid>  
      </TabPanel>
      <TabPanel value={value} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel>
      {/* <TabPanel value={value} index={3}>
        Item Four
      </TabPanel>
      <TabPanel value={value} index={4}>
        Item Five
      </TabPanel>
      <TabPanel value={value} index={5}>
        Item Six
      </TabPanel>
      <TabPanel value={value} index={6}>
        Item Seven
      </TabPanel> */}
    </div>
  );
}
