
import React, { useState, useEffect } from 'react';
import {  Grid, Typography,makeStyles,Card, Button } from '@material-ui/core'

import {CordData} from '../../mystuff/views/cardmap'
import {HomeButtonData} from '../../mystuff/views/cardmap'
import decode from 'jwt-decode';



const useStyles = makeStyles(theme=>({

    paperContent:{

        margin: theme.spacing(0),
        padding: theme.spacing(5)
    },
    roundButon:{

        "& .MuiButton-contained":{
          borderRadius:"20px",
          
         
         
        },
        "& .MuiButton-containedPrimary":{
          backgroundColor:"#6C63FF",
        
        },
       
    
      },
    container:{
        // overflowY: "scroll",
        // display:"flex",
        // height:"90vh",
        // backgroundColor:"#e9ebf0"
    },

    card:{
        
            
        
        '&:hover': {
            // border: [
            //   [1, 'solid', 'blue']
            // ],
            //
            //  boxShadow: [
            //     [2, 2, 2, 1, '#e38c09'],
                
            //   ],
              transform: "scale(1.1)"
            
            // backgroundColor:"#e38c09",
            // borderRadius:"200px",
           

        },

        gridContent:{

            margin: theme.spacing(2),
            padding: theme.spacing(4)
        },
    }



}))

export default function Home() {

    const classes = useStyles();

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));

    return (
       

        <Grid  className={classes.container} container spacing="evenly">

           
            
            <Grid className={classes.paperContent} style={{ marginTop:"50px", align:"center"}} item xs={12} md={12} >
            {/* <Paper style={{ padding:"50px", align:"center"}}> */}
            <Typography variant="h6"  align="center"> Good morning  <Typography variant="h6" style={{color:"#34eb52",display: 'inline',}}  align="center">{user?.result.name}!</Typography> What would you like to do today?</Typography>

            <Grid container className={classes.roundButon} justify="center" style={{marginTop:"50px", marginBottom:"300px"}}>
              
                {HomeButtonData.map((item, index) => {
                  return (
                 
                    <Card key={index} className={classes.card} style={{border:"1px solid #6C63FF", width:"380px", margin:"30px", height:"250px", backgroundImage:"url(" +item.icon+")", backgroundSize:"200px", backgroundRepeat:"no-repeat", backgroundPosition:"right center" }}>
                    
                    <Grid container styles={{padding:"10px"}} justify="center" direction="column">
                      <Typography align="left"  variant="h6" style={{ margin:"30px"}}>{item.header}</Typography>
                      <Typography align="left" color="textSecondary" variant="body1" style={{ margin:"5px", maxWidth:"160px", marginLeft:"30px"}}>{item.paragraph}</Typography>
                     
                      <Grid container styles={{padding:"10px"}}  direction="row">
                      <Button align="left" style={{margin:"20px", barderRadius:"10px" }}  justify="center" variant="contained" color="primary" size="large" > {item.buttonText}</Button>
                      
                        </Grid>
                        </Grid>                        
                    </Card>
                  
                  );
                })}
              
                </Grid>
                
                {/* </Paper> */}
                    

            </Grid>


        </Grid>
    )
}









