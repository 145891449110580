

export const CordData =[

    {
        icon:require("assets/img/child1.svg"),
        header:"Companion Care",
        paragraph:" Find the Companion  care that you need today",
    },
    {
        icon:require("assets/img/grandma1.svg"),
        header:"Senior Care",
        paragraph:"Find the senior care that you need today ",
     },
    
    {
        icon:require("assets/img/pet1.svg"),
        header:"24-hour Care",
        paragraph:" Find the  care that you need today"
,    },
    // {
    //     icon:<img src={require("assets/img/pet.svg")} height={imageSize} alt="Pet Care" />,
    //     header:"Pet Care",
    //     paragraph:" Families often struggle when someone they care about needs 24-hour Home Health Care. The big question is Should we move to a nursing home facility or will it be better to live at home with a live-in caregiver? " 
    // }


]


export const HomeButtonData =[

    {
        icon:require("assets/img/receipt.svg"),
        header:"Pay Invoice",
        paragraph:" Pay outstanding invoices today",
        buttonText:"Pay today"
    },
    {
        icon:require("assets/img/job.svg"),
        header:" Create new job",
        paragraph:"Create a new job today ",
        buttonText:"Create today"
     },
    
    {
        icon:require("assets/img/care.svg"),
        header:"Find Caregivers",
        paragraph:" See Caregivers that are available today",
        buttonText:"Find Care"
,    },
    // {
    //     icon:<img src={require("assets/img/pet.svg")} height={imageSize} alt="Pet Care" />,
    //     header:"Pet Care",
    //     paragraph:" Families often struggle when someone they care about needs 24-hour Home Health Care. The big question is Should we move to a nursing home facility or will it be better to live at home with a live-in caregiver? " 
    // }


]
