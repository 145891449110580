import  axios  from "axios";


const API = axios.create({ baseURL: 'http://localhost:5000' });
// const url ="http://localhost:5000/posts";

API.interceptors.request.use((req) => {
    if (localStorage.getItem('profile')) {
      req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
    }
  
    return req;
  });

export const signIn = (formData) => API.post('/users/signin', formData);
export const signUp = (formData) => API.post('/users/signup', formData);
export const fetchWorker = (id) => API.get(`/users/${id}`);
export const fetchWorkers = () => API.get('/users');
export const workersSignIn = (formData) => API.post('/users/workerssignin', formData);
export const workersSignUp = (formData) => API.post('/users/workerssignup', formData);
export const appointment = (formData) => API.post('/app/appointmet', formData);

