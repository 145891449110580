import React from "react";
import ReactDOM from "react-dom";
// import { createBrowserHistory } from "history";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk  from "redux-thunk";
import {reducers} from './Redux/reducers'
import {WorkersData} from './UserNav/components/Data/workers'

import "assets/scss/material-kit-react.scss?v=1.9.0";

// pages for this product
import Components from "views/Components/Components.js";
import SeniorSignup from "views/Components/SeniorSignup";
import Steppers from './UserNav/components/reuseable/stepper'
//import Login from "mystuff/views/login.js";
//import LandingPage from "views/LandingPage/LandingPage.js";
// import ProfilePage from "views/ProfilePage/ProfilePage.js";
import NavMenu from './UserNav/App'
import WorkerSignup from './UserNav/pages/WorkersSignup/workersSignUp'
import New from './UserNav/pages/CareGiver/careGiverDetails'

import Auth from './mystuff/Auth/signup'

// var hist = createBrowserHistory();
//console.log(Mystuff);

const store = createStore(reducers, compose(applyMiddleware(thunk)))

ReactDOM.render(
  <Provider store= {store}>
  <BrowserRouter >
    <Switch>
      {/* <Route path="/l" component={LandingPage} />
      <Route path="/profile-page" component={ProfilePage} /> */}
      <Route path="/login-page" exact component={NavMenu} />
      <Route path="/login-page/caregiver" exact component={NavMenu} />
      <Route path="/login-page/home" exact component={NavMenu} />
      <Route path="/login-page/diver" exact component={NavMenu} />
      <Route path="/login-page/messages" exact component={NavMenu} />
      <Route path="/login-page/invoices" exact component={NavMenu} />
      <Route path="/login-page/products" exact component={NavMenu} />
      <Route path="/login-page/settings" exact component={NavMenu} />
      <Route path="/login-page/support" exact component={NavMenu} />
      <Route path="/login-page/:id" exact component={NavMenu} />
      <Route path="/" exact component={Components} />
      <Route path="/newemployee" exact component={WorkerSignup} />
      <Route path="/auth" exact component={Auth} />
      <Route path="/Seniorsignup" exact component={SeniorSignup} />
    </Switch>
  </BrowserRouter>
  </Provider>,
  document.getElementById("root")

  
);


