
import React,{useState} from 'react'
import {  Grid, makeStyles,InputAdornment,Table, TableBody, TableRow, TableCell, } from '@material-ui/core'
import Input from "../components/reuseable/input"
import DatePicker from "../components/reuseable/datepicker"
import { Search } from "@material-ui/icons";
import useTable from '../components/reuseable/useTable'
import {TableData} from '../components/Data/tableData'
import ActionButton from '../components/reuseable/ActionButton'

// import NewsPost from './News/newsCard'
// import {CordData} from '../../mystuff/views/cardmap'




const useStyles = makeStyles(theme=>({

    paperContent:{

        margin: theme.spacing(0),
        padding: theme.spacing(10),
        // overflowY: "scroll",
        // display:"flex",
        // height:"90vh",
    },
    container:{
        overflowY: "scroll",
        display:"flex",
        height:"90vh",
        // backgroundColor:"#e9ebf0"
    },

    card:{
        
            
        
        '&:hover': {
            // border: [
            //   [1, 'solid', 'blue']
            // ],
            //
            //  boxShadow: [
            //     [2, 2, 2, 1, '#e38c09'],
                
            //   ],
              transform: "scale(1.1)"
            
            // backgroundColor:"#e38c09",
            // borderRadius:"200px",
           

        },

        gridContent:{

            margin: theme.spacing(2),
            padding: theme.spacing(4)
        },
    grid1:{

        height:"42px",
        display:'flex',
        flexFlow:"row wrap",
        alignItems:"center"


    },

    div:{
        display:"inline"
    }
    }



}))





export default function Invoices() {

  

    const classes = useStyles();
    const [records, setRecords] = useState(TableData)
 

    const {
        // TblContainer,
        TblHead,
        TblPagination,
    } = useTable(records);
    return (
       

        <Grid container  justify="center" alignItems="center" spacing="evenly" className={classes.paperContent}>


        <Grid item style={{borderRadius:"5px", border:"1.5px solid #9fb3ac", padding:"20px"}} xs={12}  >
        
                    <Grid container direction="row" spacing={1}  className={classes.grid1}>

                    <Grid item xs={4} md={4} lg={4}>
                            <p >Overdue</p>
                            <p >$18000.00 USD</p>
                            <p >Last updated an hour ago</p>
                    </Grid>
                    <Grid item xs={4} md={4} lg={4}>
                            <p >Overdue</p>
                            <p >$18000.00 USD</p>
                            <p >Last updated an hour ago</p>
                    </Grid>
                    <Grid item xs={4} md={4} lg={4}>
                            <p >Overdue</p>
                            <p >$18000.00 USD</p>
                            <p >Last updated an hour ago</p>
                    </Grid>

                    </Grid>

        </Grid>

        {/*/////////////////////////First Grid //////////////////////////////////////////*/}


        <Grid item xs={12} style={{marginTop:"40px"}}>
                <Grid container direction="row" spacing={1}  className={classes.grid1}>

                    <Grid item xs={4} md={4} lg={4}>
                        <Input label="Search Employees"
                                            className={classes.searchInput}
                                            InputProps={{
                                                startAdornment: (<InputAdornment position="start">
                                                    <Search />
                                                </InputAdornment>)
                                            }}
                                            ></Input>
                    </Grid>
                    <Grid item xs={4} md={4} lg={4}>
                        <DatePicker name="From" label="From" value={new Date()}></DatePicker>
                    </Grid>
                    <Grid item xs={4} md={4} lg={4}>
                        <DatePicker name="From" label="From" value={new Date()}></DatePicker>
                    </Grid>
                    
                    
                 </Grid>
       </Grid>

        {/*/////////////////////////Second Grid //////////////////////////////////////////*/}


        <Grid item xs={12} style={{marginTop:"40px"}}>
        <Table>
                    <TblHead />
                    <TableBody>
                        {
                            TableData.map(item =>
                                (<TableRow key={item.id}>
                                    <TableCell>{item.Status}</TableCell>
                                    <TableCell>{item.Due}</TableCell>
                                    <TableCell>{item.Date}</TableCell>
                                    <TableCell>{item.Number}</TableCell>
                                    <TableCell>{item.Customer}</TableCell>
                                    <TableCell>{item.AmountDue}</TableCell>
                                    <TableCell>
                                        <ActionButton
                                            color="primary"
                                            > Make a Payment
                                            {/* <EditOutlinedIcon fontSize="small" /> */}
                                        </ActionButton>
                            
                                    </TableCell>
                                </TableRow>)
                            )
                        }
                    </TableBody>
                </Table>
                <TblPagination />
        </Grid>
           
   </Grid>
        
    )
}









