
import * as api from '../api/index.js';

export const signin = (formData, router) => async (dispatch) => { /// The double function is used here because of the async 
  try {
    const { data } = await api.signIn(formData);  /// The try and catch is use because it is an async function as well 

    dispatch({ type: "AUTH", data });

    router.push('/login-page');
  } catch (error) {
    console.log(error);
  }
};

export const signup = (formData,router) => async (dispatch) => {
  try {
    const { data } = await api.signUp(formData);
    console.log(data)
    console.log(router)

    dispatch({ type: "AUTH",playload: data });

    router.push('/login-page');
  } catch (error) {
    console.log(error);
    console.log(formData)
    console.log(router)
    
  }
};
export const workersSignin = (formData, router) => async (dispatch) => { /// The double function is used here because of the async 
  try {
    const { data } = await api.signIn(formData);  /// The try and catch is use because it is an async function as well 

    dispatch({ type: "AUTH", data });

    router.push('/login-page');
  } catch (error) {
    console.log(error);
  }
};

export const workersSignup = (formData,router) => async (dispatch) => {
  try {
    const { data } = await api.workersSignUp(formData);
    console.log(data)
    console.log(router)

    dispatch({ type: "AUTH",playload: data });

    router.push('/login-page');
  } catch (error) {
    console.log(error);
    console.log(formData)
    console.log(router)
    
  }
};

export const getWorker = (id) => async (dispatch) => {
  try {
    // dispatch({ type: "START_LOADING" });

    const { data } = await api.fetchWorker(id);

    console.log(data)

    dispatch({ type: "FETCH_WORKER", payload: { worker: data } });
    // dispatch({ type: "END_LOADING" });
  } catch (error) {
    console.log(error);
  }
};
export const getWorkers = () => async (dispatch) => {
  try {
    const { data } = await api.fetchWorkers();
    console.log(data)

    dispatch({ type: "FETCH_WORKERS", payload: data });
  } catch (error) {
    console.log(error.message);
  }
};