

const workersReducer = (state = { workers:[] }, action) => {
    switch (action.type) {
     
        case "FETCH_WORKER":
        return { ...state, worker: action.payload.worker };
        case "FETCH_WORKERS":
              return { ...state, workers: action.payload }; 
              
      
      default:
        return state; /// This "state" is the old state of the app before any changes are made 
       
    }
    
  };
  
  
  export default workersReducer;