import React, { useEffect } from 'react';
import { Paper, Typography, CircularProgress, Divider,List, ListItem, ListItemIcon, ListItemText, Grid, Button } from '@material-ui/core/';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import *as FaIcons from 'react-icons/fa';
import *as ImIcons from 'react-icons/im';
import *as RiIcons from 'react-icons/ri';
import *as GiIcons from 'react-icons/gi';
import { useDispatch, useSelector } from 'react-redux';
import Rating from './ratings'
import moment from 'moment';
import { useParams, useHistory } from 'react-router-dom';

import { getWorker, getWorkers } from '../../../Redux/actions/auth';
import useStyles from './styles';
import Avatar from './Avatar/avatar'

const Post = (post) => {


 const careData =[

  {
      id: "1",
      care:"Special Needs"
  },
  {
      id: "2",
      care:"Personal Care"
  },
  {
      id: "3",
      care:"Visiting Physician"
  },
  {
      id: "4",
      care:"Dementia"
  },
  {
      id: "5",
      care:"alzheimer's"
  },
  {
      id: "6",
      care:"range of motion"
  },

]

  console.log(post)
  const { worker,workers } = useSelector((state) => state.workersReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { id } = useParams();
  const discription = "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).";

  useEffect(() => {
    dispatch(getWorker(id));
  }, [id]);
  useEffect(() => {
    dispatch(getWorkers());
  }, []);

  // useEffect(() => {
  //   if (post) {
  //     dispatch(getPostsBySearch({ search: 'none', tags: post?.tags.join(',') }));
  //   }
  // }, [post]);

  if (!worker) return null;

  const openPost = (_id) => history.push(`/login-page/${_id}`);

  // if (isLoading) {
  //   return (
  //     <Paper elevation={6} className={classes.loadingPaper}>
  //       <CircularProgress size="7em" />
  //     </Paper>
  //   );
  // }

  const recommendedWorkers = workers.filter(({ _id }) => _id !== worker._id);
  console.log(workers)
  console.log(recommendedWorkers)
  return ( 


    
    <Paper style={{ padding: '20px', borderRadius: '15px' }} elevation={6}>
      <div className={classes.card}>
        <div className={classes.section}>
          <Typography variant="h3" component="h2">{worker.name}</Typography>
          {/* <Rating  rating='5'/> */}
          {/* <Typography gutterBottom variant="h6" color="textSecondary" component="h2">{post.tags.map((tag) => `#${tag} `)}</Typography> */}
          <ListItem button key="1">
            <ListItemIcon> <Rating  rating='5'/></ListItemIcon>
            <ListItemText style={{margin:"0 0 14px 15px", opacity:".7"}} primary="(5)" />
          </ListItem>
          <ListItem button key="1">
            <ListItemIcon><FaIcons.FaMoneyBillWave style={{fontSize:"1.4rem", color:"#e61c76"}}/></ListItemIcon>
            <ListItemText primary={`$${worker.rate}- Hourly Rate`} />
          </ListItem>
          <ListItem button key="1">
            <ListItemIcon><ImIcons.ImLocation2 style={{fontSize:"1.4rem", color:"#e61c76"}}/></ListItemIcon>
            <ListItemText primary="Queens, NY" />
          </ListItem>
          <ListItem button key="1">
            <ListItemIcon><RiIcons.RiTimeFill style={{fontSize:"1.4rem", color:"#e61c76"}}/></ListItemIcon>
            <ListItemText primary="5 years Experience" />
          </ListItem>
          <ListItem button key="1">
            <ListItemIcon><GiIcons.GiTimeTrap style={{fontSize:"1.4rem", color:"#e61c76"}}/></ListItemIcon>
            <ListItemText primary="8 Hours ago" />
          </ListItem>
          <ListItem className={classes.roundButon} button key="1">
          <Button align="left" style={{margin:"20px"}}  justify="center" variant="contained" color="primary" size="large" > Message</Button>
          <Button align="left" style={{margin:"20px" }}  justify="center" variant="contained" color="primary" size="large" > Hire</Button>
            
          </ListItem>
          {/* <Typography gutterBottom variant="body1" component="p"> ${worker.rate}- Hourly Rate</Typography>
          <Typography variant="body1">Queens,NY</Typography>
          <Typography variant="body1">5 years Experience</Typography>
          <Typography variant="body1">8 Hours ago</Typography> */}
         
          {/* <Typography variant="body1">{moment(post[0].rate).fromNow()}</Typography> */}
          <Divider style={{ margin: '20px 0' }} />
          
        </div>
        <div className={classes.imageSection}>
          <img className={classes.media} src={worker.selectedFile || 'https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png'} alt={post.title} />
        </div>
      </div>
      
      <div style={{padding:"35px 40px 40px 40px"}}>
        <div className={classes.section} style={{margin:"90px 0 80px 0"}}>
          <Typography gutterBottom variant="h5">About</Typography>
          <Typography gutterBottom style={{margin:"0px 0 80px 0"}} variant="body1" component="p">{discription}</Typography>

          <Typography gutterBottom variant="h5">Services</Typography>
                  <List>
                {careData.map((care) => (
                  <ListItem button key={care.id}>
                    <ListItemIcon><CheckCircleIcon style={{color:"#14e044"}}/></ListItemIcon>
                    <ListItemText primary={care.care} />
                  </ListItem>
                ))}
              </List>
              
                
          <Typography gutterBottom variant="h5">Experience</Typography>

          <Typography gutterBottom variant="body1" component="h6">New York Presbyterian</Typography>
          <Typography gutterBottom  variant="body1" component="h5">CNA</Typography>
          <Typography gutterBottom style={{opacity:"0.7"}}  variant="body1" component="p">2015-2017</Typography>
          
          
         
         
           
          
        </div>
        <div className={classes.section}>
        
        <Typography gutterBottom variant="h5">Education</Typography>

          <Typography gutterBottom variant="body1" component="h6">CUNY Medgar Evers College</Typography>
          <Typography gutterBottom  variant="body1" component="h5">Bachelor's degree, Business Administration</Typography>
          <Typography gutterBottom style={{opacity:"0.7"}}  variant="body1" component="p">2015-2017</Typography>
          
          <Typography gutterBottom variant="h5">Interest</Typography>
                  <List>
                {careData.map((care) => (
                  <ListItem button key={care.id}>
                    <ListItemIcon><CheckCircleIcon style={{color:"#14e044"}}/></ListItemIcon>
                    <ListItemText primary={care.care} />
                  </ListItem>
                ))}
              </List>
          
        </div>
        {!!recommendedWorkers.length && (
        <div style={{margin:"40px 0 40px 0"}} className={classes.section}>
          <Typography gutterBottom variant="h5">Other Caregivers you may like:</Typography>
          <Divider />
          <div style={{margin:"40px 0 40px 0"}} className={classes.recommendedPosts}>
            {recommendedWorkers.map((worker) => (
              <Grid key={worker._id} item xs={12} sm={3} md={3}>
              <Avatar worker={worker}   />
            </Grid>
              
            ))}
          </div>
        </div>
      )}
      </div>
      
    </Paper>
  );
};

export default Post;

// , lineHeight:"3", fontWeight:"200", fontSize:"14px"

{/* <div style={{ margin: '20px', cursor: 'pointer' }} onClick={() => openPost(_id)} key={_id}>
                <Typography gutterBottom variant="h6">{title}</Typography>
                <Typography gutterBottom variant="subtitle2">{name}</Typography>
                <Typography gutterBottom variant="subtitle2">{message}</Typography>
                <img src={selectedFile|| 'https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png'} width="200px" />
              </div> */}