import React, {useState}from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import People from "@material-ui/icons/People";
import Icon from "@material-ui/core/Icon";
import Button from "components/CustomButtons/Button.js";
import { useDispatch } from 'react-redux';
import {useHistory} from 'react-router-dom'
import {signup} from '../../Redux/actions/auth'


// import Email from "@material-ui/icons/Email";
import GridContainer from "components/Grid/GridContainer.js";
 import GridItem from "components/Grid/GridItem.js";
import styles from "assets/jss/material-kit-react/views/loginPage.js";

// import Buttons from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import CustomInput from "components/CustomInput/CustomInput.js";

const useStyles = makeStyles(styles);





export default function SimpleDialog(props) {
//  const { ...rest } = props;

const history= useHistory();
const dispatch = useDispatch();
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  // const handleListItemClick = (value) => {
  //   onClose(value);
  // };


  /// This is to create user for backend //////////////// 

    const [input, setInput]= useState({

      username:"",
      password:""
    })

    function handleChange(event){

      const { name, value}= event.target;

      setInput(prevInput=>{
        return{
          ...prevInput,
          [name]:value
        }
      })
    }

    function handleClick (event) {

      handleClose();
      event.preventDefault();
      console.log(input);

      const newUser={
        username: input.username,
        password: input.password


      }
      // Axios.post("http://localhost:5000/register", newUser)
      dispatch(signup(newUser,history));
      console.log(newUser)
      console.log(history)
      // history.push("/login-page")

      setInput({
        username:"",
       password:""
      }

      )

    }



  ///////////////////////////////////////

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
     <div style={{backgroundColor:"#fa8e23"}}>
    <h1 style={{color:"white", textAlign: "center"}}>Log In</h1>
    <div className={classes.socialLine}>
                      <Button justIcon href="#pablo" target="_blank" color="transparent" onClick={e => e.preventDefault()} > <i style={{color:"white"}} className={"fab fa-twitter"} /> </Button>
                      <Button justIcon href="#pablo" target="_blank" color="transparent" onClick={e => e.preventDefault()} > <i  style={{color:"white"}} className={"fab fa-facebook"} /> </Button>
                      <Button justIcon href="#pablo" target="_blank" color="transparent" onClick={e => e.preventDefault()} > <i style={{color:"white"}} className={"fab fa-google-plus-g"} /> </Button>
                      </div>
     </div>
     <GridContainer alignItems="center" justify="center">

     
            <GridItem xs={6} sm={6} md={6}>

           

              <CustomInput labelText="Username" name="username" onChange={handleChange} value={input.username} id="pass" formControlProps={{ fullWidth: true }} inputProps={{ type: "email", endAdornment: ( <InputAdornment position="end"> <People className={classes.inputIconsColor} /> </InputAdornment> ), autoComplete: "off" }} />
             <CustomInput labelText="Password" name="password" onChange={handleChange} value={input.password} id="pass" formControlProps={{ fullWidth: true }} inputProps={{ type: "password", endAdornment: ( <InputAdornment position="end"> <Icon > lock_outline </Icon> </InputAdornment> ), autoComplete: "off" }} />  
      
                   
                     </GridItem> 
                      <GridItem>
                      <div style={{textAlign:"center"}}><Button onClick={handleClick} simple color="primary" size="lg">
                      Get started
                    </Button>
                    </div>
                    
                      </GridItem>

                      
                     
                    
                     </GridContainer>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

// export default function SimpleDialogDemo() {
//   const [open, setOpen] = React.useState(false);
//   const [selectedValue, setSelectedValue] = React.useState(emails[1]);

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = (value) => {
//     setOpen(false);
//     setSelectedValue(value);
//   };

//   return (
//     <div>
//       <Typography variant="subtitle1">Selected: {selectedValue}</Typography>
//       <br />
//       <Button variant="outlined" color="primary" onClick={handleClickOpen}>
//         Open simple dialog
//       </Button>
//       <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose} />
//     </div>
//   );
// }
