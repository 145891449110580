import React from 'react'

import { Grid,Typography } from '@material-ui/core';
import WorkerReuse from './CareGiver/workersReuseable'
// import useStyles from "../pages/Avatar/styles";


// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.background.paper,
//     width: 1000,
//     margin:" 40px 0px 0px 500px",
//     padding:"40px",
//   },
//   scroll:{
//     overflowY: "scroll",
//     display:"flex",
//     height:"74vh",

//   }
// }));
export default function Caregiver() {
    // const classes = useStyles();

   
    return (
       

        <Grid container direction="column" >

            <Typography align="center" variant="h3" style={{ margin:"80px"}}>Our Senior Caregivers  </Typography>

                <WorkerReuse/>
            
        </Grid>
    
       
    );
}




 
