import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  media: {
    borderRadius: '20px',
    objectFit: 'cover',
    maxWidth: '800px',
    maxHeight: '600px',
    [theme.breakpoints.down('lg')]: {
      marginLeft: "20px",
      maxWidth: '500px',
      minHeight: '300px',
      
    },

  },
  card: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      flexDirection: 'column',
    },
  },
  section: {
    borderRadius: '20px',
    margin: '10px',
    flex: 1,
  },
  imageSection: {
    marginRigt: '20px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: "20px",
      
    },
  },
  recommendedPosts: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  roundButon:{

    "& .MuiButton-contained":{
      borderRadius:"20px",
      
     
     
    },
    "& .MuiButton-containedPrimary":{
      backgroundColor:"#6C63FF",
    
    },
   

  },
  loadingPaper: {
    display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px', borderRadius: '15px', height: '39vh',
  },
}));